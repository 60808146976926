import React, { Component } from "react";
import { DefaultTemplate } from "../templates/default-template";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import AppContext from "../context/AppContext";

export class Mapa extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
    };
  }

  _prepareComponents = () => {
    const lang = this.context.lang || "es";

    let _components = [
      {
        layout: "c-label-page",
        fields: {
          label: this.context.trans("general.map_byline"),
          link: false,
          showIcon: true,
          highlight: false,
          showBorderBottom: true,
        },
      },
      {
        layout: "c-map",
        fields: null,
        extra_params: {
          rp_base_link: lang === "en" ? "en/map" : "es/mapa",
        },
      },
    ];
    let _rp_data = {
      title: this.context.trans("general.map"),
      slug: "mapa",
      primaryColor: "var(--color-e)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data });
  };

  componentDidMount() {
    // console.log('page.js did mount');
    this._prepareComponents();
  }

  render() {
    let _title =
      this.context.trans("general.the_filmin_times") +
      " - " +
      this.context.trans("general.map");
    let _metadesc = this.context.trans("general.map_byline");
    let _meta = [];
    let _url = window.location.href;

    return (
      <>
        <Helmet
          title={_title}
          titleTemplate={`%s | ${_metadesc}`}
          meta={[
            {
              property: `og:title`,
              content: _title,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            // {
            //     property: `og:image`,
            //     content: _image,
            // },
            {
              property: `og:url`,
              content: _url,
            },
            {
              name: `description`,
              content: _metadesc,
            },
            {
              property: `og:description`,
              content: _metadesc,
            },
          ].concat(_meta)}
          onChangeClientState={(newState, addedTags, removedTags) => {
            // console.log(newState, addedTags, removedTags)
            // console.log(newState.title);
            window.dispatchEvent(new CustomEvent("update_to_share"));
            ReactGA.pageview(window.location.pathname + window.location.search);
          }}
        />
        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
