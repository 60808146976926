import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";
import { DefaultTemplate } from "../templates/default-template";

import rp_fetch_data from "../data/fetch-data.json";
const {
  base_url,
  headers,
  localized_headers,
  url_get_countries,
  url_get_media,
} = rp_fetch_data;

export class DetalleMapaPelicula extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      rp_movie: {},
      is_redirect: false,
    };
  }

  _setRedirect = () => {
    this.setState({
      is_redirect: true,
    });
  };

  _renderRedirect = () => {
    if (this.state.is_redirect) {
      return <Redirect to={`/${this.context.lang}/404`} />;
    }
  };

  _fetchData(all_countries) {
    let _country = all_countries.filter(
      (item) => item.slug === this.props.match.params.slug
    );

    let _current_slug_movie = this.props.match.params.slug_movie;
    let _url_media =
      base_url + url_get_media.replace("__param__", _current_slug_movie);

    if (_country.length === 0) {
      this._setRedirect();
    } else {
      Promise.all([
        fetch(_url_media, {
          method: "GET",
          headers: new Headers({
            ...headers,
            ...localized_headers[this.context.lang],
          }),
        }),
      ])
        .then(([res]) => Promise.all([res.json()]))
        .then(([rp_data]) => {
          if (rp_data.errors) {
            console.log(rp_data);
            this._setRedirect();
          } else {
            this._prepareComponents(rp_data, all_countries);
          }
        });
    }
  }

  _prepareComponents = (rp_data, all_countries) => {
    let _country = all_countries.filter(
      (item) => item.slug === this.props.match.params.slug
    );

    let _components = [
      {
        layout: "c-movie",
        fields: rp_data.data,
        extra_params: {
          rp_section: "country",
        },
      },
    ];

    let _link =
      this.context.lang === "en"
        ? `/en/map/${this.props.match.params.slug}`
        : `/es/mapa/${this.props.match.params.slug}`;

    _components.unshift({
      layout: "c-label-page",
      fields: {
        label: _country[0].name,
        link: _link,
        showIcon: true,
        highlight: true,
        showBorderBottom: true,
      },
    });
    // VARIABLE COLOR OR CREATE MORE PAGES
    let _rp_data = {
      title: _country[0].name,
      slug: _country[0].slug,
      primaryColor: "var(--color-e)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data, rp_movie: rp_data.data });
  };

  componentDidMount() {
    let _url = base_url + url_get_countries;

    Promise.all([
      fetch(_url, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
    ])
      .then(([res_countries]) => Promise.all([res_countries.json()]))
      .then(([rp_data_countries]) => {
        if (rp_data_countries.errors) {
          console.log(rp_data_countries);
        } else {
          this._fetchData(rp_data_countries.data);
        }
      });
  }

  render() {
    return (
      <>
        {this._renderRedirect()}
        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
