import React, { Component } from "react";
import { BNotFound } from "../blocks/b-not-found/b-not-found";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import AppContext from "../context/AppContext";

export class NotFound extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = { results: [] };
  }

  render() {
    let _title =
      this.context.trans("general.the_filmin_times") +
      " - " +
      this.context.trans("general.404_error");
    let _metadesc = this.context.trans("general.404_error_description");
    let _meta = [];
    let _url = window.location.href;

    return (
      <>
        <Helmet
          title={_title}
          titleTemplate={`%s | ${_metadesc}`}
          meta={[
            {
              property: `og:title`,
              content: _title,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            {
              property: `og:url`,
              content: _url,
            },
            {
              name: `description`,
              content: _metadesc,
            },
            {
              property: `og:description`,
              content: _metadesc,
            },
          ].concat(_meta)}
          onChangeClientState={(newState, addedTags, removedTags) => {
            // console.log(newState, addedTags, removedTags)
            window.dispatchEvent(new CustomEvent("update_to_share"));
            ReactGA.pageview(window.location.pathname + window.location.search);
          }}
        />
        <BNotFound />
      </>
    );
  }
}
