import React from "react";

export default React.createContext({
  lang: "es",
  trans: () => {
    throw new Error(
      "No translator initialized (please review the usage of <AppContext.Provider/>)"
    );
  },
  transChoice: () => {
    throw new Error(
      "No translator intialized (please review the usage of <AppContext.Provider/>)"
    );
  },
});
