import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// * STYLES
import "./m-close-button.scss";

const b = "m-close-button";

export default class M_close_button extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
    };
  }

  render() {
    const { link, showIcon } = this.props;

    if (!showIcon) return null;

    return (
      <div className={`${b}`}>
        <NavLink to={link} className={`${b}__button`}>
          <svg
            className={`${b}__icon`}
            viewBox="0 0 19 19"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth="1">
              <g transform="translate(-1250.000000, -32.000000)">
                <polygon points="1250.72059 33.7790262 1252.40598 32 1259.70935 39.7091136 1267.03519 32 1268.72059 33.7790262 1261.39475 41.4881398 1268.72059 49.2209738 1267.03519 51 1259.70935 43.267166 1252.40598 51 1250.72059 49.2209738 1258.02396 41.4881398"></polygon>
              </g>
            </g>
          </svg>
        </NavLink>
      </div>
    );
  }
}
