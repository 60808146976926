import React from "react";

// THIS COMPONENT

// * STYLES
import "./b-debug-columns.scss";

const B_debug_columns = class extends React.Component {
  componentDidMount() {
    window.addEventListener("keypress", this.onKeypress, false);
  }

  componentWillUnmount() {
    // you need to unbind the same listener that was binded.
    window.removeEventListener("keypress", this.onKeypress, false);
  }

  onKeypress = (e) => {
    // Do action on CTRL + .
    if (e.keyCode === 46 && e.ctrlKey) {
      this.rp_debug_columns();
    }
  };

  rp_debug_columns() {
    let _body = document.querySelector("body");
    _body.classList.toggle("is-debug");
    return false;
  }

  render() {
    return null;
  }
};

export default B_debug_columns;
