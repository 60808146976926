import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../context/AppContext";

import World from "../../assets/maps/world/world-map";
import { SVGMap } from "react-svg-map";
import "react-svg-map/lib/index.css";

// * STYLES
import "./c-map-render.scss";

const b = "c-map-render";

export default class C_map_render extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    // console.log(this.props.countries)

    this.state = {
      rp_data: {},
      currentCountry: null,
      pointedLocation: null,
      selectedLocation: null,
      redirect_to: null,
      tooltipStyle: {
        display: "none",
      },
    };
  }

  _handleLocationMouseOver = (event) => {
    let _current_code = event.target.attributes.id.value;
    let _country = this.props.countries.filter(
      (item) => item.iso_code.toUpperCase() === _current_code.toUpperCase()
    );
    if (_country.length > 0) {
      this.setState({
        pointedLocation: _country[0].name,
        currentCountry: _country[0],
      });
    } else {
    }
  };

  _handleLocationMouseOut = () => {
    this.setState({
      pointedLocation: null,
      tooltipStyle: { display: "none" },
      currentCountry: null,
    });
  };

  _handleLocationMouseMove = (event) => {
    const { currentCountry } = this.state;

    if (currentCountry) {
      const tooltipStyle = {
        display: "block",
        top: event.clientY - 80,
        left: event.clientX - 100,
      };
      this.setState({ tooltipStyle });
    }
  };

  _getLocationClassName = (location, index) => {
    // Generate random heat map
    let _current_code = location.id;
    let _country = this.props.countries.filter(
      (item) => item.iso_code.toUpperCase() === _current_code.toUpperCase()
    );
    if (_country.length > 0) {
      return `${b}__svg-location`;
    } else {
      return `${b}__svg-location is-inactive`;
    }
  };

  _handleOnLocationClick = (event) => {
    let _current_code = event.target.attributes.id.value;
    let _country = this.props.countries.filter(
      (item) => item.iso_code.toUpperCase() === _current_code.toUpperCase()
    );
    if (_country.length > 0) {
      this.setState({ redirect_to: _country[0].slug });
    }
  };

  render() {
    return (
      <div className={`${b} js-${b}`}>
        <div className={`${b}__wrapper`}>
          <SVGMap
            map={World}
            className={`${b}__svg`}
            locationClassName={this._getLocationClassName}
            onLocationMouseOver={this._handleLocationMouseOver}
            onLocationMouseOut={this._handleLocationMouseOut}
            onLocationMouseMove={this._handleLocationMouseMove}
            onLocationClick={this._handleOnLocationClick}
          />
          <div className={`${b}__svg-tooltip`} style={this.state.tooltipStyle}>
            {this.state.pointedLocation}
          </div>
        </div>
        {this.state.redirect_to && (
          <Redirect
            to={
              this.context.lang === "en"
                ? `/en/map/${this.state.redirect_to}`
                : `/es/mapa/${this.state.redirect_to}`
            }
          />
        )}
      </div>
    );
  }
}
