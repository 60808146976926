import React from "react";
import AppContext from "./context/AppContext";
import translatorByLang from "./data/translations/translator";

export const AppProvider = ({ lang, children }) => {
  const translator = translatorByLang[lang];

  return (
    <AppContext.Provider
      value={{
        lang,
        trans: translator.trans,
        transChoice: translator.transChoice,
      }}
    >
      {/* App is wrapped in a keyed fragment to cause a full rerender when language changes. (translator.trans() is otherwise not reactive, nor are data fetching components designed to work reactively) */}
      <React.Fragment key={lang}>{children}</React.Fragment>
    </AppContext.Provider>
  );
};
