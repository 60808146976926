import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import * as moment from "moment";
import "moment/locale/es";

// * STYLES
import "./b-header.scss";

import singleRoutes from "../../data/single-routes.json";

import global_settings from "../../data/global-settings.json";

import BShare from "../b-share/b-share";
import AppContext from "../../context/AppContext";

const b = "b-header";

export default class B_header extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.menu,
    };
  }

  componentDidMount() {
    // console.log('b-header mount');
  }

  render() {
    const { menu } = this.state;

    let _current_css = global_settings["filmin_css_color_a"];

    const links = menu.map(({ to, label, extra_info }, index) => {
      let _exact = to["es"] === "/es/" ? true : true; // * CHANGE LAST TRUE BY FALSE?

      const _to = to[this.context.lang] || to["es"];

      return (
        <NavLink
          strict
          exact={_exact}
          to={_to}
          key={_to}
          activeClassName="is-active"
          className={`${b}__menu-item | js-header__menu-item`}
          data-key={index + 1}
          isActive={(match, location) => {
            // MATCH IS NULL -> THEN CHECK DETAILS
            if (!match) {
              let _result = singleRoutes.find(
                (item) =>
                  (item.activate_this_menu[this.context.lang] ||
                    item.activate_this_menu["es"]) ===
                  (to[this.context.lang] || to["es"])
              );
              if (_result) {
                if (
                  location.pathname.includes(
                    _result.url_slug[this.context.lang] ||
                      _result.url_slug["es"]
                  )
                ) {
                  return true;
                }
              }

              return false;
            }

            return true;
          }}
        >
          <div className={`${b}__menu-item-text`}>
            {label}
            {index === 0 && (
              <>
                <div className={`${b}__menu-item-label-date`}>
                  <span>{moment().format("D MMMM YYYY")}</span>
                </div>
                <div className={`${b}__menu-item-label-info`}>
                  <span>{extra_info}</span>
                </div>
              </>
            )}
          </div>
        </NavLink>
      );
    });

    let _logo_the_img = "";

    try {
      _logo_the_img = require(`../../assets/images/MediaLogo.svg`);
      // do stuff
    } catch (ex) {
      console.log(ex);
    }

    // console.log(process.env)

    return (
      <header className={`${b} js-header`}>
        <div className={`${b}__menu`}>
          {links}
          <BShare extra_classes={"in-menu"} />
          <div className={`${b}__menu-buttons`}>
            <div className={`${b}__language-picker`}>
              <NavLink
                to={`/es/`}
                className={`${b}__language-link`}
                activeClassName="is-active"
              >
                ES
              </NavLink>
              |
              <NavLink
                to={`/en/`}
                className={`${b}__language-link`}
                activeClassName="is-active"
              >
                EN
              </NavLink>
            </div>
            <div className={`${b}__button`}>
              {_current_css && (
                <iframe
                  title="Header button account"
                  className={`${b}__iframe`}
                  src={`https://filmin.es/tft/iframe-button-account?lang=${this.context.lang || 'es'}&style=${process.env.REACT_APP_API_URL}${_current_css}`}
                >
                  {" "}
                </iframe>
              )}
            </div>
            {_logo_the_img && (
              <div className={`${b}__wrapper-logo-partner`}>
                <img
                  className={`${b}__logo-partner`}
                  src={_logo_the_img}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
      </header>
    );
  }
}
