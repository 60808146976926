import React, { Component } from "react";
import { TimelineMax as Timeline, Power1 } from "gsap";
import CSSPlugin from "gsap/CSSPlugin";

// GSAP
// import { TweenMax, TweenLite, TimelineMax, CSSPlugin, ScrollToPlugin, Draggable, Power1 } from "gsap/all";

// THIS COMPONENT

// * STYLES
import "./b-loader.scss";

const the_CSSPlugin = CSSPlugin; // NEEDED FOR THE BUILD

// SPACE
// TimelineMax.prototype.addSpace = function (position) {
//     return this.set({}, {}, position);
// };

const b = "b-loader";

const B_loader = class extends Component {
  componentDidMount() {
    // this.loader_out();
    // console.log('b-loader is mount');
    document.addEventListener("custom_event", this.loader_out);
  }

  loader_out() {
    // console.log('loader out');

    let _loader = document.querySelector(".js-loader");

    new Timeline()
      .to(_loader, 2, {
        // NOTHING
      })
      .addLabel("start")
      .to(
        _loader,
        0.3,
        {
          autoAlpha: 0,
          ease: Power1.easeOut,
        },
        "start"
      )
      .call(function () {}, [], this);
  }

  render() {
    return (
      <div className={`${b} js-loader`}>
        <div className={`${b}__item`}></div>
      </div>
    );
  }
};

export default B_loader;
