import React, { useContext } from "react"
import AppContext from "../../context/AppContext";

import MCloseButton from "../_modules/m-close-button/m-close-button";

// * STYLES
import "./c-label-page.scss";

export default (props) => {
  const context = useContext(AppContext);
  const b = props.rp_layout;
  const rp_fields = props.rp_fields;

  // console.log(rp_fields)

  const { label, link, highlight, showIcon, showBorderBottom, icon } =
    rp_fields;

  let _highlight_class = highlight ? `${b}--highlight` : "";
  let _show_icon_class = showIcon ? `${b}--show-icon` : "";
  let _show_border_bottom_class = showBorderBottom
    ? `${b}--show-border-bottom`
    : "";

  let _showIcon = showIcon ? showIcon : false;

  let _icon = icon ? icon : "close";

  let _link = link ? link : `/${context?.lang || 'es'}/`;

  return (
    <div
      className={`${b} ${_highlight_class} ${_show_icon_class} ${_show_border_bottom_class}`}
    >
      <div className={`${b}__wrapper`}>
        <div className={`${b}__wrapper-label`}>
          <h2 className={`${b}__label`}>{label}</h2>
        </div>
        {_showIcon && (
          <div className={`${b}__wrapper-close`}>
            {_icon === "close" ? (
              <MCloseButton link={_link} showIcon={_showIcon} />
            ) : (
              <MCloseButton link={_link} showIcon={true} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
