import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AppContext from "../../context/AppContext";

// * STYLES
import "./c-medias.scss";

export default class C_list_featured extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
    };
  }

  componentDidMount() {}

  componentDidUpdate() {}

  rp_month_string(month) {
    const monthNames = this.context.trans("general.months").split("\n");

    return monthNames[month - 1];
  }

  _create_item = (item, index, b, _url, _extra_class, _base_page) => {
    let _media_slug = item.slug;
    _extra_class = _extra_class === false ? "" : _extra_class;

    let _the_image = item[`image`];
    let _the_img_string = false;

    if (!!_the_image) {
      let _pos = _the_image.lastIndexOf(".");
      if (_pos !== -1) {
        _the_img_string = _the_image.substring(0, _pos) + "_1440x1440.jpg";
      }
    }

    let _month = this.rp_month_string(item[`featured_on_month`]);

    let _date_string =
      this.context.lang === "en"
        ? `${_month} ${item[`featured_on_day`]}, ${item[`year`]}`
        : `${item[`featured_on_day`]} de ${_month} de ${item[`year`]}`;

    return (
      <NavLink
        key={index}
        to={`/${_url}/${_media_slug}`}
        className={`${b}__item ${_extra_class}`}
      >
        {_the_img_string && (
          <div className={`${b}__wrapper-image`}>
            <LazyLoadImage
              src={_the_img_string} // use normal <img> attributes as props
              placeholder={<div className={`${b}__placeholder`}></div>}
              effect="opacity"
            />
          </div>
        )}
        <div className={`${b}__wrapper-title`}>
          <h3 className={`${b}__title`}>{item[`headline`]}</h3>
        </div>
        {_base_page === "destacado" && item["featured_on_day"] && (
          <div className={`${b}__wrapper-info`}>
            <div className={`${b}__info-text`}>
              <span
                className={`${b}__info-text-highlight`}
              >{`${_date_string}`}</span>{" "}
              {`${item[`title`]}`}
            </div>
            <div className={`${b}__info-sep`}></div>
          </div>
        )}
        {_base_page !== "destacado" && (
          <div className={`${b}__wrapper-info`}>
            <div className={`${b}__info-text`}>{item[`title`]}</div>
            <div className={`${b}__info-sep`}>({item[`year`]}) /</div>
          </div>
        )}
      </NavLink>
    );
  };

  render() {
    const b = this.props.rp_layout;
    const rp_fields = this.props.rp_fields;
    const rp_extra_params = this.props.rp_extra_params;

    let _extra_url = rp_extra_params["rp_extra_link"]
      ? "/" + rp_extra_params["rp_extra_link"]
      : "";
    let _url = rp_extra_params["rp_base_link"] + _extra_url;

    let _first_col_count = Math.ceil(rp_fields.length * 0.4);
    let _first_col_items = rp_fields.slice(0, _first_col_count);
    let _second_col_items = rp_fields.slice(_first_col_count);

    let _extra_class = `${b}__item--alt`;

    const first_list_items = _first_col_items.map((item, index) => {
      return this._create_item(
        item,
        index,
        b,
        _url,
        false,
        rp_extra_params["rp_base_link"]
      );
    });

    const second_list_items = _second_col_items.map((item, index) => {
      return this._create_item(
        item,
        index,
        b,
        _url,
        _extra_class,
        rp_extra_params["rp_base_link"]
      );
    });

    return (
      <div className={`${b} js-${b}`}>
        <div className={`${b}__first-col`}>{first_list_items}</div>
        <div className={`${b}__second-col`}>{second_list_items}</div>
      </div>
    );
  }
}
