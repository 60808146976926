import React, { Component } from "react";
import { NavLink } from "react-router-dom";

// * STYLES
import "./c-grid.scss";

export class MyItem extends Component {
  render() {
    const { rp_extra_params, item, b, _the_img, _the_img_hover, _image_slug } =
      this.props.fields;

    return (
      <NavLink
        to={`/${rp_extra_params["rp_base_link"]}/${item["slug"]}`}
        className={`${b}__item`}
      >
        <div className={`${b}__wrapper-image`}>
          <img className={`${b}__image`} src={_the_img} alt="" />
          {_the_img_hover && (
            <img
              className={`${b}__image ${b}__image--hover`}
              src={_the_img_hover}
              alt=""
            />
          )}
        </div>
        <div className={`${b}__wrapper-title`}>
          <h3 className={`${b}__title`}>
            {_image_slug === "icon-character"
              ? item[`name`] + " " + item[`last_name`]
              : item[`name`]}
          </h3>
        </div>
      </NavLink>
    );
  }
}

export default class C_grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      redirect_to: false,
    };
  }

  render() {
    const b = this.props.rp_layout;
    const rp_fields = this.props.rp_fields;
    const rp_extra_params = this.props.rp_extra_params;

    const grid_items = rp_fields.map((item, index) => {
      let _image_slug = /(es|en)\/(personaje|character)/.test(
        rp_extra_params["rp_base_link"]
      )
        ? "icon-character"
        : "icon-event";
      let _the_img = "";
      let _the_img_hover = "";

      try {
        _the_img = require(`../../assets/all-icons/${_image_slug}__${
          item["image_slug"] || item["slug"]
        }.svg`);
      } catch (ex) {
        // console.log(ex);
      }

      try {
        _the_img_hover = require(`../../assets/all-icons/${_image_slug}__${
          item["image_slug"] || item["slug"]
        }-hover.svg`);
      } catch (ex2) {
        // console.log(ex);
      }

      return (
        <MyItem
          key={index}
          fields={{
            rp_extra_params,
            item,
            b,
            _the_img,
            _the_img_hover,
            _image_slug,
          }}
        />
      );
    });

    return <div className={`${b} js-${b}`}>{grid_items}</div>;
  }
}
