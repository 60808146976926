import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../context/AppContext";

import * as moment from "moment";
import "moment/locale/es";

// * STYLES
import "./b-menu-dropdown.scss";

import singleRoutes from "../../data/single-routes.json";

import global_settings from "../../data/global-settings.json";

const b = "b-menu-dropdown";

export default class B_menu_dropdown extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      menu: this.props.menu,
      is_show: "",
    };
  }

  _toggleMenu = () => {
    let _current_state = document.querySelector(".js-menu-dropdown");
    let _current_class = _current_state.classList.contains("is-show");

    if (_current_class === false) {
      _current_state.classList.add("is-show");
    } else {
      _current_state.classList.remove("is-show");
    }
  };

  componentDidMount() {
    // console.log('b-header mount');
    // let _menu_items = document.getElementsByClassName('js-menu-dropdown__menu-item');
    // console.log(_menu_items)
  }

  render() {
    const { menu } = this.state;

    let _current_css = global_settings["filmin_css_color_a"];

    const links = menu.map(({ to, label, extra_info }, index) => {
      let _exact = to["es"] === "/es/" ? true : true; // * CHANGE LAST TRUE BY FALSE?

      const _to = to[this.context.lang] || to["es"];

      return (
        <NavLink
          strict
          exact={_exact}
          to={_to}
          key={_to}
          activeClassName="is-active"
          className={`${b}__menu-item | js-menu-dropdown__menu-item`}
          onClick={this._toggleMenu}
          data-key={index + 1}
          isActive={(match, location) => {
            // MATCH IS NULL -> THEN CHECK DETAILS
            if (!match) {
              let _result = singleRoutes.filter(
                (item) =>
                  (item.activate_this_menu[this.context.lang] ||
                    item.activate_this_menu["es"]) ===
                  (to[this.context.lang] || to["es"])
              );
              if (_result.length) {
                if (
                  location.pathname.includes(
                    _result[0].url_slug[this.context.lang] ||
                      _result[0].url_slug["es"]
                  )
                ) {
                  return true;
                }
              }

              return false;
            }

            return true;
          }}
        >
          <div className={`${b}__menu-item-text`}>
            {label}
            {index === 0 && (
              <>
                <div className={`${b}__menu-item-label-date`}>
                  <span>{moment().format("D MMMM YYYY")}</span>
                </div>
                <div className={`${b}__menu-item-label-info`}>
                  <span>{extra_info}</span>
                </div>
              </>
            )}
          </div>
        </NavLink>
      );
    });

    let _logo_the_img = "";

    try {
      _logo_the_img = require(`../../assets/images/MediaLogo.svg`);
      // do stuff
    } catch (ex) {
      console.log(ex);
    }

    return (
      <div className={`${b} js-menu-dropdown`}>
        <div className={`${b}__wrapper-hamburguer`} onClick={this._toggleMenu}>
          <div className={`${b}__hamburguer`}>
            <div className={`${b}__hamburguer-icon`}></div>
          </div>
        </div>
        <div className={`${b}__menu`}>
          {links}
          <div className={`${b}__menu-buttons`}>
            <div className={`${b}__button`}>
              {_current_css && (
                <iframe
                  title="Button account"
                  className={`${b}__iframe`}
                  src={`https://filmin.es/tft/iframe-button-account?lang=${this.context.lang || 'es'}&style=${process.env.REACT_APP_API_URL}${_current_css}`}
                >
                  {" "}
                </iframe>
              )}
            </div>
            {_logo_the_img && (
              <div className={`${b}__wrapper-dropdown-footer`}>
                <div className={`${b}__language-picker`}>
                  <NavLink
                    to={`/es/`}
                    className={`${b}__language-link`}
                    activeClassName="is-active"
                  >
                    ES
                  </NavLink>
                  |
                  <NavLink
                    to={`/en/`}
                    className={`${b}__language-link`}
                    activeClassName="is-active"
                  >
                    EN
                  </NavLink>
                </div>
                <div className={`${b}__logo-partner-link`}>
                  <img
                    className={`${b}__logo-partner`}
                    src={_logo_the_img}
                    alt=""
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
