import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import { play, exit } from "./timelines/timelines";
import { AppProvider } from "./AppProvider";
import Cookies from "js-cookie";

import ReactGA from "react-ga";

// CSS GENERAL
import "./App.css";

// IMPORT TIPO
import "./assets/fonts/BridgeHead-TypeMates/BridgeHead-TypeMates.css";

// IMPORT TIPO
import "./assets/fonts/PTSerif/PTSerif.css";

// BEMIT
import "./bemit/bemit.scss";

// BLOCKS
import Header from "./header";

// PAGES

import { DetalleAcontecimientoPelicula } from "./pages/DetalleAcontecimientoPelicula";
import { DetalleTimelinePelicula } from "./pages/DetalleTimelinePelicula";
import { DetallePersonajePelicula } from "./pages/DetallePersonajePelicula";
import { DetalleMapaPelicula } from "./pages/DetalleMapaPelicula";

import { DetalleDestacado } from "./pages/DetalleDestacado";
import { DetalleAcontecimiento } from "./pages/DetalleAcontecimiento";
import { DetalleTimeline } from "./pages/DetalleTimeline";
import { DetallePersonaje } from "./pages/DetallePersonaje";
import { DetalleMapa } from "./pages/DetalleMapa";

import { Home } from "./pages/Home";
import { Acontecimientos } from "./pages/Acontecimientos";
import { Timeline } from "./pages/Timeline";
import { Personajes } from "./pages/Personajes";
import { Mapa } from "./pages/Mapa";

import { NotFound } from "./pages/NotFound";
import AppContext from "./context/AppContext";

ReactGA.initialize("UA-159452521-1");

window.loadPromise = new Promise(function (resolve) {
  window.addEventListener("load", resolve);
});

function listenMessage(event) {
  if (event.data === "get_current_url") {
    event.source.postMessage(
      {
        currentURL: document.URL,
      },
      "*"
    );
  }
}
window.addEventListener("message", listenMessage, false);

let _body = document.querySelector("body");

const isTouch =
  "ontouchstart" in window ||
  navigator.msMaxTouchPoints > 0 ||
  navigator.maxTouchPoints;
if (isTouch) {
  _body.classList.add("touch");
} else {
  _body.classList.add("no-touch");
  _body.classList.add("o-overflow-hidden");
}

function eventResize(event) {
  let w = window,
    d = document,
    e = d.documentElement,
    g = document.body,
    x = w.innerWidth || e.clientWidth || g.clientWidth;
  //y = w.innerHeight || e.clientHeight || g.clientHeight;

  if (x < 1400) {
    _body.classList.remove("o-overflow-hidden");
  } else {
    _body.classList.add("o-overflow-hidden");
  }
}
window.addEventListener("resize", eventResize, false);
eventResize();

class App extends Component {
  _updateVars = () => {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  componentDidMount() {
    window.addEventListener("resize", this._updateVars);
    this._updateVars();
  }

  componentDidUpdate() {
    this._updateVars();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateVars);
  }

  render() {
    // Cookies.remove('tft-cookies')

    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <Redirect to="/es/" />
          </Route>
          <Route
            path="/:lang"
            render={({ location, match }) => {
              const { path, params } = match;
              const { pathname, key } = location;

              if (pathname === "/es" || pathname === "/en") {
                // Add trailing slash if not present
                return <Redirect to={`${pathname}/`} />;
              }

              if (params.lang !== "es" && params.lang !== "en") {
                return <Redirect to="/es/404" />;
              }

              return (
                <AppProvider lang={params.lang}>
                  <div className="app">
                    <Header />
                    <TransitionGroup component={null}>
                      <Transition
                        key={key}
                        appear={true}
                        onEnter={(node, appears) =>
                          play(pathname, node, appears)
                        }
                        onExit={(node, appears) => exit(node, appears)}
                        timeout={{ enter: 750, exit: 150 }}
                      >
                        <Switch>
                          <Route exact path={`${path}/`} component={Home} />
                          <Route
                            path={[
                              `${path}/destacado/:slug`,
                              `${path}/featured/:slug`,
                            ]}
                            component={DetalleDestacado}
                          />
                          <Route
                            path={[
                              `${path}/acontecimiento/:slug/:slug_movie`,
                              `${path}/event/:slug/:slug_movie`,
                            ]}
                            component={DetalleAcontecimientoPelicula}
                          />
                          <Route
                            path={[
                              `${path}/acontecimiento/:slug`,
                              `${path}/event/:slug`,
                            ]}
                            component={DetalleAcontecimiento}
                          />
                          <Route
                            exact
                            path={[`${path}/acontecimientos`, `${path}/events`]}
                            component={Acontecimientos}
                          />
                          <Route
                            path={`${path}/timeline/:slug/:slug_movie`}
                            component={DetalleTimelinePelicula}
                          />
                          <Route
                            path={`${path}/timeline/:slug`}
                            component={DetalleTimeline}
                          />
                          <Route
                            exact
                            path={`${path}/timeline`}
                            component={Timeline}
                          />
                          <Route
                            path={[
                              `${path}/personaje/:slug/:slug_movie`,
                              `${path}/character/:slug/:slug_movie`,
                            ]}
                            component={DetallePersonajePelicula}
                          />
                          <Route
                            path={[
                              `${path}/personaje/:slug`,
                              `${path}/character/:slug`,
                            ]}
                            component={DetallePersonaje}
                          />
                          <Route
                            exact
                            path={[`${path}/personajes`, `${path}/characters`]}
                            component={Personajes}
                          />
                          <Route
                            path={[
                              `${path}/mapa/:slug/:slug_movie`,
                              `${path}/map/:slug/:slug_movie`,
                            ]}
                            component={DetalleMapaPelicula}
                          />
                          <Route
                            path={[`${path}/mapa/:slug`, `${path}/map/:slug`]}
                            component={DetalleMapa}
                          />
                          <Route
                            exact
                            path={[`${path}/mapa`, `${path}/map`]}
                            component={Mapa}
                          />
                          <Route
                            exact
                            path={`${path}/404`}
                            component={NotFound}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </Transition>
                    </TransitionGroup>

                    {Cookies.get("tft-cookies") === undefined && (
                      <div className="cookies js-cookies is-open">
                        <div className="cookies__container">
                          <p>
                            Uso de cookies en el sitio{" "}
                            <a
                              className="cookies__link"
                              rel="noopener noreferrer"
                              target="_blank"
                              href="https://www.filmin.es/"
                              title="aquí"
                            >
                              www.filmin.es
                            </a>
                            <br />
                            Utilizamos cookies, propias y de terceros, para
                            mejorar nuestros servicios mediante el análisis de
                            sus hábitos de navegación. Haga click en el botón de
                            aceptar para confirmar su uso. <br />
                            Para más información acerca de la configuración y
                            uso de las cookies pulse{" "}
                            <a
                              className="cookies__link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href="https://www.filmin.es/aviso-legal"
                              title="aquí"
                            >
                              aquí
                            </a>
                          </p>
                          <div
                            className="cookies__btn cookies__accept js-cookies-accept"
                            onClick={() => {
                              Cookies.set("tft-cookies", "ok");
                              let _cookies =
                                document.querySelector(".js-cookies");
                              _cookies.classList.remove("is-open");
                            }}
                          >
                            <span className="cookies__accept-text">
                              aceptar
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </AppProvider>
              );
            }}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
