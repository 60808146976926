import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";
import { DefaultTemplate } from "../templates/default-template";
import Helmet from "react-helmet";
import ReactGA from "react-ga";

import rp_fetch_data from "../data/fetch-data.json";
const { base_url, headers, localized_headers, url_get_event, url_get_medias } =
  rp_fetch_data;

export class DetalleAcontecimiento extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      is_redirect: false,
    };
  }

  _setRedirect = () => {
    this.setState({
      is_redirect: true,
    });
  };

  _renderRedirect = () => {
    if (this.state.is_redirect) {
      return <Redirect to={`/${this.context.lang}/404`} />;
    }
  };

  _fetchData() {
    let _current_slug = this.props.match.params.slug;
    let _url = base_url + url_get_event.replace("__param__", _current_slug);
    let _url_medias = _url + url_get_medias;

    Promise.all([
      fetch(_url, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
      fetch(_url_medias, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([rp_data1, rp_data2]) => {
        if (rp_data1.errors) {
          this._setRedirect();
        }
        if (rp_data1.errors || rp_data2.errors) {
          console.log(rp_data1, rp_data2);
        } else {
          this._prepareComponents(rp_data1, rp_data2);
        }
      });
  }

  _prepareComponents = (rp_data1, rp_data2) => {
    const lang = this.context.lang || "es";

    let _components = [
      {
        layout: "c-info",
        fields: rp_data1.data,
        extra_params: {
          rp_base_link: lang === "en" ? "en/events" : "es/acontecimientos",
        },
      },
      {
        layout: "c-medias",
        fields: rp_data2.data,
        extra_params: {
          rp_base_link: lang === "en" ? "en/event" : "es/acontecimiento",
          rp_extra_link: this.props.match.params.slug,
        },
      },
    ];
    let _rp_data = {
      title: rp_data1.data.name,
      slug: rp_data1.data.slug,
      primaryColor: "var(--color-b)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data });
  };

  componentDidMount() {
    this._fetchData();
  }

  render() {
    let _title =
      this.context.trans("general.the_filmin_times") +
      " - " +
      this.context.transChoice("general.event", 0);
    let _metadesc = "";
    let _meta = [];
    let _url = window.location.href;

    if (this.state.rp_data) {
      _metadesc = this.state.rp_data.title;
    }

    return (
      <>
        {this._renderRedirect()}
        {this.state.rp_data && (
          <Helmet
            title={_title}
            titleTemplate={`%s | ${_metadesc}`}
            meta={[
              {
                property: `og:title`,
                content: _title,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: _url,
              },
              {
                name: `description`,
                content: _metadesc,
              },
              {
                property: `og:description`,
                content: _metadesc,
              },
            ].concat(_meta)}
            onChangeClientState={(newState, addedTags, removedTags) => {
              // console.log(newState, addedTags, removedTags)
              window.dispatchEvent(new CustomEvent("update_to_share"));
              ReactGA.pageview(
                window.location.pathname + window.location.search
              );
            }}
          />
        )}

        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
