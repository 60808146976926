import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../context/AppContext";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// * STYLES
import "./c-movie.scss";

import global_settings from "../../data/global-settings.json";
import BShare from "../../blocks/b-share/b-share";

export default class C_movie extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { isOpen } = this.state;

    const b = this.props.rp_layout;
    const rp_fields = this.props.rp_fields;
    const rp_extra_params = this.props.rp_extra_params;

    let _directors = rp_fields[`directors`].map((item) => {
      return item.name + " " + item.last_name;
    });
    let _calc_hours = rp_fields[`duration`] / 60;
    let _duration_hour = Math.trunc(_calc_hours);
    let _string_duration = _duration_hour + "h";

    if (_calc_hours % 1 !== 0) {
      let _minutes = (_calc_hours % 1) * 60;
      _duration_hour = Math.trunc(_calc_hours);
      _string_duration += " " + Math.round(_minutes) + "min";
    }

    let _the_headline = rp_fields[`headlines`][rp_extra_params.rp_section]
      ? rp_fields[`headlines`][rp_extra_params.rp_section]
      : rp_fields[`headlines`][`featured`];

    let _the_image = rp_fields[`images`][rp_extra_params.rp_section];
    if (!_the_image) {
      _the_image = rp_fields[`images`][`poster`];
    }

    let _pos = _the_image.lastIndexOf(".");
    let _the_img_string = "";
    if (_pos !== -1) {
      _the_img_string = _the_image.substring(0, _pos) + "_1440x1440.jpg";
    }

    let _current_color = getComputedStyle(
      document.documentElement
    ).getPropertyValue("--current-primary-color-string");

    let _current_css = false;

    if (!!_current_color) {
      _current_color = _current_color.replace("var(--color-", "");
      _current_color = _current_color.replace(")", "");
      _current_color = _current_color.replace('"', "");
      _current_color = _current_color.replace('"', "");
      _current_css = global_settings["filmin_css_color_" + _current_color];
    }

    let _highlight_text = "";
    let _normal_text = rp_fields[`excerpt`];

    if (rp_extra_params.rp_section === "timeline") {
      _normal_text = rp_fields[`timeline_excerpt`];
      if (!_normal_text) {
        _normal_text = rp_fields[`excerpt`];
      }
    }

    let helmet_title = _the_headline + " | The Filmin Times";
    let helmet_metadesc = "";
    let helmet_image = _the_img_string;
    let helmet_meta = [];
    let _url = window.location.href;

    if (!!_normal_text) {
      helmet_metadesc = _normal_text;
      let _first_comma = _normal_text.indexOf(", ");
      let _first_dot = _normal_text.indexOf(". ");
      let _end_first_text =
        _first_dot > _first_comma && _first_comma > 0
          ? _first_comma
          : _first_dot;

      _end_first_text = _end_first_text + 1;
      _highlight_text = _normal_text.substring(0, _end_first_text);
      _normal_text = _normal_text.substring(_end_first_text);
    }

    let _description_text = rp_fields[`synopsis`];

    let _the_image_poster = rp_fields[`images`][`poster`];

    let _pos_poster = _the_image_poster.lastIndexOf(".");
    let _the_img_poster_string = "";
    let _the_img_poster_big_string = "";
    if (_pos_poster !== -1) {
      _the_img_poster_string =
        _the_image_poster.substring(0, _pos_poster) + "_600x900.jpg";
      _the_img_poster_big_string =
        _the_image_poster.substring(0, _pos_poster) + "_1440x1440.jpg";
    }

    const _events = rp_fields[`events`].map((item, index) => {
      let _image_slug = "icon-event";
      let _the_img = "";
      try {
        _the_img = require(`../../assets/all-icons/${_image_slug}__${item["slug"]}.svg`);
        // do stuff
      } catch (ex) {
        console.log(ex);
      }
      return (
        <NavLink
          key={index}
          to={
            this.context.lang === "en"
              ? `/en/event/${item["slug"]}`
              : `/es/acontecimiento/${item["slug"]}`
          }
          className={`${b}__item`}
        >
          <div className={`${b}__wrapper-item-image`}>
            <img className={`${b}__item-image`} src={_the_img} alt="" />
          </div>
          <div className={`${b}__wrapper-item-title`}>
            <span className={`${b}__item-title`}>{item[`name`]}</span>
          </div>
        </NavLink>
      );
    });

    const _chars = rp_fields[`characters`].map((item, index) => {
      let _image_slug = "icon-character";
      let _the_img = "";
      try {
        _the_img = require(`../../assets/all-icons/${_image_slug}__${item["slug"]}.svg`);
        // do stuff
      } catch (ex) {
        console.log(ex);
      }
      return (
        <NavLink
          key={index}
          to={
            this.context.lang === "en"
              ? `/en/character/${item["slug"]}`
              : `/es/personaje/${item["slug"]}`
          }
          className={`${b}__item`}
        >
          <div className={`${b}__wrapper-item-image`}>
            <img className={`${b}__item-image`} src={_the_img} alt="" />
          </div>
          <div className={`${b}__wrapper-item-title`}>
            <span className={`${b}__item-title`}>
              {item[`name`] + " " + item[`last_name`]}
            </span>
          </div>
        </NavLink>
      );
    });

    const _countries = rp_fields[`countries`].map((item, index) => {
      let _the_img = "";
      try {
        _the_img = require(`../../assets/all-icons-flags/${item[
          "country_code"
        ].toUpperCase()}.svg`);
        // do stuff
      } catch (ex) {
        console.log(ex);
      }
      return (
        <NavLink
          key={index}
          to={
            this.context.lang === "en"
              ? `/en/map/${item["slug"]}`
              : `/es/mapa/${item["slug"]}`
          }
          className={`${b}__item`}
        >
          <div className={`${b}__wrapper-item-image`}>
            <img className={`${b}__item-image`} src={_the_img} alt="" />
          </div>
          <div className={`${b}__wrapper-item-title`}>
            <span className={`${b}__item-title`}>{item[`name`]}</span>
          </div>
        </NavLink>
      );
    });

    const _books = rp_fields[`books`].map((item, index) => {
      return (
        <a
          key={index}
          href={item["external_link"]}
          target="_blank"
          className={`${b}__item | ${b}__item--book`}
          rel="noopener noreferrer"
        >
          <div
            className={`${b}__wrapper-item-image | ${b}__wrapper-item-image--book`}
          >
            <img
              className={`${b}__item-image | ${b}__item-image--book`}
              src={item["image"]}
              alt=""
            />
          </div>
          <div>
            <div
              className={`${b}__wrapper-item-title | ${b}__wrapper-item-title--book`}
            >
              <span className={`${b}__item-title | ${b}__item-title--book`}>
                {item[`title`]}
              </span>
            </div>
            <div
              className={`${b}__wrapper-item-authors | ${b}__wrapper-item-authors--book`}
            >
              <span className={`${b}__item-authors | ${b}__item-authors--book`}>
                {item[`authors`]}
              </span>
            </div>
            <div
              className={`${b}__wrapper-item-editorial | ${b}__wrapper-item-editorial--book`}
            >
              <span
                className={`${b}__item-editorial | ${b}__item-editorial--book`}
              >
                {item[`editorial_name`]}
              </span>
            </div>
          </div>
        </a>
      );
    });

    return (
      <div className={`${b} js-${b}`}>
        <div className={`${b}__wrapper-title`}>
          <h1 className={`${b}__title`}>{_the_headline}</h1>
        </div>
        <div className={`${b}__body`}>
          <div className={`${b}__wrapper-image`}>
            <img className={`${b}__image`} src={_the_img_string} alt="" />
            {_the_img_string && (
              <Helmet
                title={helmet_title}
                titleTemplate={helmet_title}
                meta={[
                  {
                    property: `og:title`,
                    content: helmet_title,
                  },
                  {
                    property: `og:type`,
                    content: `video.movie`,
                  },
                  {
                    property: `og:image`,
                    content: helmet_image,
                  },
                  {
                    property: `og:url`,
                    content: _url,
                  },
                  {
                    name: `description`,
                    content: helmet_metadesc,
                  },
                  {
                    property: `og:description`,
                    content: helmet_metadesc,
                  },
                ].concat(helmet_meta)}
                onChangeClientState={(newState, addedTags, removedTags) => {
                  // console.log(newState, addedTags, removedTags)
                  window.dispatchEvent(new CustomEvent("update_to_share"));
                  ReactGA.pageview(
                    window.location.pathname + window.location.search
                  );
                }}
              />
            )}
          </div>
          <div className={`${b}__wrapper-detail`}>
            <div className={`${b}__details`}>
              <div className={`${b}__detail-caption`}>
                <b>'{rp_fields[`original_title`]}'</b>
              </div>
              <div className={`${b}__detail-director`}>
                <b>{this.context.trans("general.direction")}: </b>
                {_directors.join(", ")}
              </div>
              <div className={`${b}__detail-duration`}>
                <b>{this.context.trans("general.duration")}: </b>
                {_string_duration}
              </div>
              <div className={`${b}__detail-country`}>
                <b>{this.context.trans("general.country")}: </b>
                {rp_fields[`country_names`]}
              </div>
              <div className={`${b}__qualification-code`}>
                <b>{this.context.trans("general.age")}: </b>
                {rp_fields[`qualification_code`]}
              </div>
            </div>
            <div className={`${b}__detail-see`}>
              {_current_css && (
                <iframe
                  title="Movie button play"
                  className={`${b}__iframe`}
                  src={`https://filmin.es/tft/iframe-button-play?lang=${
                    this.context.lang || "es"
                  }&mediaSlug=${rp_fields["slug"]}&mediaType=${
                    rp_fields["type"]
                  }&style=${process.env.REACT_APP_API_URL}${_current_css}`}
                ></iframe>
              )}
              {_current_css && (
                <iframe
                  title="Movie button watch later"
                  className={`${b}__iframe`}
                  src={`https://filmin.es/tft/iframe-button-watch-later?lang=${
                    this.context.lang || "es"
                  }&mediaSlug=${rp_fields["slug"]}&style=${
                    process.env.REACT_APP_API_URL
                  }${_current_css}`}
                ></iframe>
              )}
              <BShare extra_classes={"is-big"} />
            </div>
          </div>
        </div>
        <div className={`${b}__wrapper-info`}>
          <div className={`${b}__info-text`}>{rp_fields[`title`]}</div>
          <div className={`${b}__info-sep`}>({rp_fields[`year`]}) /</div>
        </div>
        <div className={`${b}__wrapper-extra`}>
          <div className={`${b}__col-related`}>
            <div className={`${b}__wrapper-content`}>
              <div className={`${b}__content-title`}>{_highlight_text}</div>
              <p className={`${b}__the-content`}>{_normal_text}</p>
            </div>
            {_description_text && (
              <div className={`${b}__wrapper-description`}>
                <div
                  className={`${b}__the-description`}
                  dangerouslySetInnerHTML={{ __html: _description_text }}
                ></div>
              </div>
            )}
            {rp_fields[`events`].length > 0 && (
              <>
                <div className={`${b}__wrapper-block-title`}>
                  <span className={`${b}__block-title`}>
                    {this.context.transChoice("general.event", 0)}
                  </span>
                </div>
                <div className={`${b}__wrapper-block`}>{_events}</div>
              </>
            )}
            {rp_fields[`timelines`].length > 0 && (
              <>
                <div className={`${b}__wrapper-block-title`}>
                  <span className={`${b}__block-title`}>
                    {this.context.transChoice("general.timeline", 1)}
                  </span>
                </div>
                <div className={`${b}__wrapper-block-timeline`}>
                  <NavLink
                    to={`/${this.context.lang}/timeline/${
                      rp_fields[`timelines`][0]["slug"]
                    }`}
                    className={`${b}__timeline`}
                  >
                    <span className={`${b}__timeline-start`}>
                      {rp_fields[`timelines`][0][`start_year`]}
                    </span>
                    <span className={`${b}__timeline-sep`}></span>
                    <span className={`${b}__timeline-end`}>
                      {rp_fields[`timelines`][0][`end_year`]}
                    </span>
                  </NavLink>
                </div>
              </>
            )}
            {rp_fields[`characters`].length > 0 && (
              <>
                <div className={`${b}__wrapper-block-title`}>
                  <span className={`${b}__block-title`}>
                    {this.context.transChoice("general.character", 0)}
                  </span>
                </div>
                <div className={`${b}__wrapper-block`}>{_chars}</div>
              </>
            )}
            {rp_fields[`countries`].length > 0 && (
              <>
                <div className={`${b}__wrapper-block-title`}>
                  <span className={`${b}__block-title`}>
                    {this.context.trans("general.map")}
                  </span>
                </div>
                <div className={`${b}__wrapper-block`}>{_countries}</div>
              </>
            )}
            {rp_fields[`books`].length > 0 && (
              <>
                <div className={`${b}__wrapper-block-title`}>
                  <span className={`${b}__block-title`}>
                    {this.context.trans("general.bibliography")}
                  </span>
                </div>
                <div className={`${b}__wrapper-rows`}>{_books}</div>
              </>
            )}
          </div>
          <div className={`${b}__col-poster`}>
            <div className={`${b}__wrapper-poster`}>
              <img
                className={`${b}__poster`}
                src={_the_img_poster_string}
                onClick={() => this.setState({ isOpen: true })}
                alt=""
              />
            </div>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={_the_img_poster_big_string}
            reactModalStyle={{
              overlay: {
                zIndex: 99999999,
              },
            }}
            onCloseRequest={() => this.setState({ isOpen: false })}
          />
        )}
      </div>
    );
  }
}
