import React, { useContext } from "react";
import PropTypes from "prop-types";
import HEAD from "./head";

import BAccess from "./blocks/b-access/b-access";
import BLoader from "./blocks/b-loader/b-loader";
import BDebugColums from "./blocks/b-debug-columns/b-debug-columns";
import BHeader from "./blocks/b-header/b-header";
import BMenuDropdown from "./blocks/b-menu-dropdown/b-menu-dropdown";

import get_header_menu from "./data/header-menu";

import Cookies from "js-cookie";
import AppContext from "./context/AppContext";

const Header = (props) => {
  const { trans, transChoice } = useContext(AppContext);
  const header_menu = get_header_menu({ trans, transChoice });

  return (
    <>
      <HEAD title={props.siteTitle} />
      {Cookies.get("tft-cookies") === undefined && <BAccess />}
      <BLoader />
      <BDebugColums />
      <BHeader menu={header_menu} />
      <BMenuDropdown menu={header_menu} />
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  lang: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: "Filmin",
  lang: "es",
};

export default Header;
