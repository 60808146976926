import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../context/AppContext";

// * STYLES
import "./c-featured.scss";

export default class C_featured extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
    };
  }

  componentDidMount() {
    // window.onload = this._resizeAllGridItems();
    // window.addEventListener("resize", this._resizeAllGridItems);
  }

  componentDidUpdate() {}

  _resizeAllGridItems = () => {
    // allItems = document.getElementsByClassName("item");
    // for(x=0;x<allItems.length;x++){
    //     resizeGridItem(allItems[x]);
    // }
  };

  rp_month_string(month) {
    return this.context.trans("general.months").split("\n")[month - 1];
  }

  render() {
    const b = this.props.rp_layout;
    const rp_fields = this.props.rp_fields;
    const rp_extra_params = this.props.rp_extra_params;

    let _media_slug = rp_fields["slug"];

    let _the_image = rp_fields[`image`];
    let _the_img_string = false;

    if (!!_the_image) {
      let _pos = _the_image.lastIndexOf(".");
      if (_pos !== -1) {
        _the_img_string = _the_image.substring(0, _pos) + "_1440x1440.jpg";
      }
    }

    let _month = this.rp_month_string(rp_fields[`featured_on_month`]);

    let _date_string =
      this.context.lang === "en"
        ? `${_month} ${rp_fields[`featured_on_day`]}, ${rp_fields[`year`]}`
        : `${rp_fields[`featured_on_day`]} de ${_month} de ${
            rp_fields[`year`]
          }`;

    return (
      <div className={`${b} js-${b}`}>
        <div className={`${b}__item`}>
          <NavLink
            to={`/${rp_extra_params["rp_base_link"]}/${_media_slug}`}
            className={`${b}__wrapper-image`}
          >
            {_the_img_string && (
              <img className={`${b}__image`} src={_the_img_string} alt="" />
            )}
          </NavLink>
          <div className={`${b}__wrapper-title`}>
            <NavLink
              to={`/${rp_extra_params["rp_base_link"]}/${_media_slug}`}
              className={`${b}__title-link`}
            >
              <h1 className={`${b}__title`}>{rp_fields[`headline`]}</h1>
            </NavLink>
          </div>
          <div className={`${b}__wrapper-info`}>
            <div className={`${b}__info-text`}>
              <span
                className={`${b}__info-text-highlight`}
              >{`${_date_string}`}</span>{" "}
              {`${rp_fields[`title`]}`}
            </div>
            <div className={`${b}__info-sep`}></div>
          </div>
        </div>
      </div>
    );
  }
}
