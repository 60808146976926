import { createTranslator } from "@filmin/translations";
import generalEN from "./en-GB/general.json";
import generalES from "./es-ES/general.json";

// Initialize translation object by language
const translatorByLang = {
  es: createTranslator(),
  en: createTranslator(),
};

// Load all keys for all languages
translatorByLang.es.addKeys(generalES);
translatorByLang.en.addKeys(generalEN);

export default translatorByLang;
