import React, { Component } from "react";
import AppContext from "../../context/AppContext";

import {
  FacebookShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
} from "react-share";

// * STYLES
import "./b-share.scss";

const b = "b-share";

const B_share = class extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      title_share: "",
      URL_share: "",
    };
  }

  _updateVars = () => {
    const shareUrl =
      process.env.REACT_APP_API_URL +
      window.location.pathname +
      window.location.search;
    const title = document.title;

    this.setState({ title_share: title, URL_share: shareUrl });
  };

  componentDidMount() {
    window.addEventListener("update_to_share", this._updateVars);
  }

  componentWillUnmount() {
    window.removeEventListener("update_to_share", this._updateVars);
  }

  render() {
    const shareUrl = this.state.URL_share;
    const title = this.state.title_share;

    // console.log(shareUrl, title)

    return (
      <div className={`${b} js-${b} ${this.props.extra_classes}`}>
        <div className={`${b}__button`}>
          <svg
            width="16px"
            viewBox="0 0 22 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g
                transform="translate(-508.000000, -791.000000)"
                fill="#F4F2EE"
                fillRule="nonzero"
              >
                <g transform="translate(503.000000, 784.000000)">
                  <path d="M18.6190476,7 L27,14.4074154 L18.6190476,21.8148159 L18.6190476,17.962938 C18.173181,17.9392343 10.4012357,17.6567457 5,23 C7.1615,15.4659625 13.950281,12.188973 18.6190476,10.8518483 L18.6190476,7 Z"></path>
                </g>
              </g>
            </g>
          </svg>
          {this.props.extra_classes === "is-big" && (
            <div className={`${b}__label-share`}>
              {this.context.trans("general.share")}
            </div>
          )}
        </div>
        <div className={`${b}__items`}>
          <FacebookShareButton url={shareUrl} quote={title}>
            <FacebookIcon size={30} round />
            {this.props.extra_classes === "is-big" && (
              <div className={`${b}__label-icon`}>Facebook</div>
            )}
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} title={title}>
            <TwitterIcon size={30} round />
            {this.props.extra_classes === "is-big" && (
              <div className={`${b}__label-icon`}>Twitter</div>
            )}
          </TwitterShareButton>
        </div>
      </div>
    );
  }
};

export default B_share;
