import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Select, { components } from "react-select";
import AppContext from "../../context/AppContext";

// * STYLES
import "./c-select.scss";

const b = "c-select";

const customStyles = {
  indicatorsSeparator: (base) => ({
    ...base,
  }),
  indicatorsContainer: (base) => {
    // console.log(base);
    return {
      ...base,
    };
  },
  control: (base) => ({
    ...base,
    width: 300,
    borderRadius: 8,
    border: "1px solid var(--color-e)",
    backgroundColor: "var(--color-e)",
    color: "var(--color-white)",
    // overflow: 'hidden',
    // position: 'relative',
    outline: "0px solid white",
    boxShadow: "none",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid var(--color-e)",
    },
  }),
  placeholder: (base) => ({
    ...base,
    // width: 300,
    // borderRadius: 8,
    // border: "1px solid var(--color-e)",
    // backgroundColor: "var(--color-e)",
    backgroundColor: "var(--color-e)",
    color: "var(--color-white)",
    textTransform: "uppercase",
    fontFamily: "var(--font-b__bold)",
    fontSize: "12px",
    lineHeight: "24px",
  }),
  singleValue: (base) => ({
    ...base,
    // width: 300,
    // borderRadius: 8,
    // border: "1px solid var(--color-e)",
    // backgroundColor: "var(--color-e)",
    backgroundColor: "var(--color-e)",
    color: "var(--color-white)",
    textTransform: "uppercase",
    fontFamily: "var(--font-b__bold)",
    fontSize: "12px",
    lineHeight: "24px",
  }),
  valueContainer: (base) => ({
    ...base,
    // width: 300,
    // borderRadius: 8,
    // border: "1px solid var(--color-e)",
    // backgroundColor: "var(--color-e)",
    backgroundColor: "var(--color-e)",
    color: "var(--color-white)",
  }),
  input: (base) => {
    // console.log(base)
    return {
      ...base,
      // // width: 300,
      // borderRadius: 8,
      // border: "1px solid var(--color-e)",
      // backgroundColor: "var(--color-e)",
      color: "var(--color-white)",
    };
  },
  menu: (base) => {
    return {
      ...base,
      width: 300,
      borderRadius: 8,
      border: "1px solid var(--color-e)",
      backgroundColor: "var(--color-white)",
      marginTop: 0,
    };
  },
  menuList: (base) => ({
    ...base,
    padding: "10px 20px",
  }),
  option: (base, state) => {
    let _color = state.isFocused ? "var(--color-e)" : "var(--color-e)";
    _color = state.isSelected ? "var(--color-white)" : _color;

    let _bg_color = state.isFocused
      ? "rgba(246, 13, 73, 0.1)"
      : "var(--color-white)";
    _bg_color = state.isSelected ? "var(--color-e)" : _bg_color;

    return {
      // ...base,
      borderBottom: `1px solid var(--color-e)`,
      padding: "5px 0px",
      textTransform: "uppercase",
      fontFamily: "var(--font-b__bold)",
      fontSize: "12px",
      lineHeight: "24px",
      cursor: "pointer",
      color: _color,
      backgroundColor: _bg_color,
    };
  },
};

const IndicatorsContainer = (props) => {
  return (
    <div style={{ paddingRight: 14 }}>
      <components.IndicatorsContainer {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
        >
          <path d="M12 21l-12-18h24z" fill="var(--color-white)" />
        </svg>
      </components.IndicatorsContainer>
    </div>
  );
};

export default class C_select extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      redirect_to: false,
    };
  }

  _changeValue = (e) => {
    const { value } = e;
    let _country = this.props.countries.filter(
      (item) => item.iso_code.toUpperCase() === value.toUpperCase()
    );
    this.props.parentCallback(_country[0]);
    this.setState({ redirect_to: _country[0].slug });
  };

  render() {
    const options = this.props.countries.map((item) => {
      return {
        value: item.iso_code.toUpperCase(),
        label: item.name,
      };
    });

    return (
      <div className={`${b} js-${b}`}>
        <Select
          placeholder={this.context.trans("general.see_country_list")}
          styles={customStyles}
          options={options}
          isSearchable={false}
          onChange={this._changeValue}
          components={{ IndicatorsContainer }}
        />
        {this.state.redirect_to && <Redirect to={this.state.redirect_to} />}
      </div>
    );
  }
}
