import React from "react";
import BFooter from "./blocks/b-footer/b-footer";

const Footer = () => (
  <>
    <BFooter></BFooter>
  </>
);

export default Footer;
