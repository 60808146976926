import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

function HEAD({ description, lang, meta, title }) {
  const metaDescription = description || "";

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${metaDescription}`}
      // meta={[
      //     {
      //         name: `description`,
      //         content: metaDescription,
      //     },
      //     {
      //         property: `og:title`,
      //         content: title,
      //     },
      //     {
      //         property: `og:description`,
      //         content: metaDescription,
      //     },
      //     {
      //         property: `og:type`,
      //         content: `website`,
      //     },
      //     {
      //         name: `twitter:card`,
      //         content: `summary`,
      //     },
      //     {
      //         name: `twitter:creator`,
      //         content: 'autor',
      //     },
      //     {
      //         name: `twitter:title`,
      //         content: title,
      //     },
      //     {
      //         name: `twitter:description`,
      //         content: metaDescription,
      //     },
      // ].concat(meta)}
    />
  );
}

HEAD.defaultProps = {
  lang: `es`,
  meta: [],
  description: `Web`,
};

HEAD.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default HEAD;
