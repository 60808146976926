import React, { Component } from "react";
import CText from "./c-text/c-text";
import CFeatured from "./c-featured/c-featured";
import CMedias from "./c-medias/c-medias";
import CMovie from "./c-movie/c-movie";
import CGrid from "./c-grid/c-grid";
import CInfo from "./c-info/c-info";
import CListTimelines from "./c-list-timelines/c-list-timelines";
import CMap from "./c-map/c-map";
import CLabelPage from "./c-label-page/c-label-page";
import BShare from "../blocks/b-share/b-share";

export default class All_components extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    // console.log('all_components constructor');
  }

  componentDidMount() {
    // console.log('all_components didmount');
    setTimeout(() => {
      document.dispatchEvent(new CustomEvent("custom_event"));
    }, 200);
  }

  componentDidUpdate() {
    // console.log('all_components didupdate');
    // document.dispatchEvent(new CustomEvent("custom_event"));
    //
  }

  render() {
    const all_components = this.props.rp_components;

    let _check_movie_layout = all_components.filter(
      (item) => item.layout === "c-movie"
    );
    let is_detail_page = _check_movie_layout.length > 0;

    return (
      <>
        {all_components.map((component, index) => {
          let rp_component_html = "";

          switch (component.layout) {
            case "c-text":
              rp_component_html = (
                <CText
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-featured":
              rp_component_html = (
                <CFeatured
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-medias":
              rp_component_html = (
                <CMedias
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-movie":
              rp_component_html = (
                <CMovie
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-grid":
              rp_component_html = (
                <CGrid
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-info":
              rp_component_html = (
                <CInfo
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-list-timelines":
              rp_component_html = (
                <CListTimelines
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-map":
              rp_component_html = (
                <CMap
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            case "c-label-page":
              rp_component_html = (
                <CLabelPage
                  key={index}
                  rp_layout={component.layout}
                  rp_fields={component.fields}
                  rp_extra_params={component.extra_params}
                />
              );
              break;

            default:
              break;
          }

          return rp_component_html;
        })}
        {is_detail_page === false && <BShare extra_classes={"in-footer"} />}
      </>
    );
  }
}
