import { TimelineMax as Timeline, Power1 } from "gsap";

const end_anims = () => {
  // console.log('end_anims')
  let _body = document.querySelector("body");
  _body.classList.remove("is-changing");
};

const start_anims = () => {
  // console.log('start_anims')
  let _body = document.querySelector("body");
  _body.classList.add("is-changing");
};

const updateMenuItemsVarsCSS = () => {
  let _items = document.querySelectorAll(".js-header__menu-item");
  let _itemActive = document.querySelector(".js-header__menu-item.is-active");
  if (!_itemActive) {
    _itemActive = document.querySelector(".js-header__menu-item");
  }
  document.documentElement.style.setProperty(
    "--menu-item-children-active",
    _itemActive.dataset.key
  );
  document.documentElement.style.setProperty("--menu-items", _items.length);
};

const getDefaultTimeline = (node, delay) => {
  const timeline = new Timeline({ paused: true });
  const content = node.querySelector(".js-page-transition");
  const contentInner = node.querySelector(".js-page-transition-inner");

  timeline
    .from(node, 0.3, {
      display: "none",
      autoAlpha: 0,
      delay,
      ease: Power1.easeIn,
    })
    .from(content, 0.15, { autoAlpha: 0, y: 25, ease: Power1.easeInOut })
    .from(contentInner, 0.15, {
      autoAlpha: 0,
      delay: 0.15,
      ease: Power1.easeIn,
    });

  timeline.call(updateMenuItemsVarsCSS);
  timeline.call(end_anims);

  return timeline;
};

// const getHomeTimeline = (node, delay) => {
//     const timeline = new Timeline({ paused: true });
//     const texts = node.querySelectorAll('h1 > div');

//     timeline
//         .from(node, 0, { display: 'none', autoAlpha: 0, delay })
//         .staggerFrom(texts, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125);

//     return timeline;
// }

export const play = (pathname, node, appears) => {
  const delay = appears ? 0 : 0.5;
  let timeline = getDefaultTimeline(node, delay);

  window.loadPromise.then(() => requestAnimationFrame(() => timeline.play()));
};

export const exit = (node) => {
  const timeline = new Timeline({ paused: true });

  let _current_state = document.querySelector(".js-menu-dropdown.is-show");
  // console.log(_current_state)
  if (_current_state) _current_state.classList.remove("is-show");

  timeline.call(start_anims);
  timeline.to(node, 0.15, { autoAlpha: 0, ease: Power1.easeOut });
  timeline.call(updateMenuItemsVarsCSS);
  timeline.play();
};
