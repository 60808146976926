import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import AppContext from "../../context/AppContext";

// * STYLES
import "./b-not-found.scss";

const b = "b-not-found";

export class BNotFound extends Component {
  static contextType = AppContext;

  componentDidMount() {
    document.dispatchEvent(new CustomEvent("custom_event"));
  }

  render() {
    return (
      <div className={`${b}`}>
        <div className={`${b}__wrapper`}>
          <div className={`${b}__wrapper-text`}>
            <h1 className={`${b}__text`}>
              {this.context.trans("general.404_error")}:{" "}
              {this.context.trans("general.404_error_description")}
            </h1>
            <div className={`${b}__wrapper-button`}>
              <NavLink to={`/`} className={`${b}__button`}>
                {this.context.trans("general.back_to_home")}
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
