export default ({ trans, transChoice }) => [
  {
    to: {
      es: "/es/",
      en: "/en/",
    },
    label: trans("general.the_filmin_times"),
    extra_info: trans("general.site_byline"),
  },
  {
    to: {
      es: "/es/acontecimientos/",
      en: "/en/events/",
    },
    label: transChoice("general.event", 0),
  },
  {
    to: {
      es: "/es/timeline/",
      en: "/en/timeline/",
    },
    label: transChoice("general.timeline", 1),
  },
  {
    to: {
      es: "/es/personajes/",
      en: "/en/characters/",
    },
    label: transChoice("general.character", 0),
  },
  {
    to: {
      es: "/es/mapa/",
      en: "/en/map/",
    },
    label: trans("general.map"),
  },
];
