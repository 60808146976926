import React, { Component } from "react";
import { DefaultTemplate } from "../templates/default-template";
import Helmet from "react-helmet";
import ReactGA from "react-ga";
import AppContext from "../context/AppContext";

import rp_fetch_data from "../data/fetch-data.json";
const { base_url, headers, localized_headers, url_get_timelines } =
  rp_fetch_data;

export class Timeline extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
    };
  }

  _fetchData() {
    let _url = base_url + url_get_timelines;
    fetch(_url, {
      method: "GET",
      headers: new Headers({
        ...headers,
        ...localized_headers[this.context.lang],
      }),
    })
      .then((res) => res.json())
      .then((rp_data) => {
        if (rp_data.errors) {
          console.log(rp_data);
        } else {
          this._prepareComponents(rp_data);
        }
      });
  }

  _prepareComponents = (rp_data) => {
    const lang = this.context.lang || "es";

    let _components = [
      {
        layout: "c-label-page",
        fields: {
          label: this.context.transChoice("general.timeline_byline", 1),
          link: false,
          showIcon: true,
          highlight: false,
          showBorderBottom: true,
        },
      },
      {
        layout: "c-list-timelines",
        fields: rp_data.data,
        extra_params: {
          rp_base_link: `${lang}/timeline`,
        },
      },
    ];
    let _rp_data = {
      title: this.context.transChoice("general.timeline", 0),
      slug: "timeline",
      primaryColor: "var(--color-c)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data });
  };

  componentDidMount() {
    this._fetchData();
  }

  render() {
    let _title =
      this.context.trans("general.the_filmin_times") +
      " - " +
      this.context.transChoice("general.timelines", 0);
    let _metadesc = this.context.trans("general.timeline_byline");
    let _meta = [];
    let _url = window.location.href;

    return (
      <>
        <Helmet
          title={_title}
          titleTemplate={`%s | ${_metadesc}`}
          meta={[
            {
              property: `og:title`,
              content: _title,
            },
            {
              property: `og:type`,
              content: `website`,
            },
            // {
            //     property: `og:image`,
            //     content: _image,
            // },
            {
              property: `og:url`,
              content: _url,
            },
            {
              name: `description`,
              content: _metadesc,
            },
            {
              property: `og:description`,
              content: _metadesc,
            },
          ].concat(_meta)}
          onChangeClientState={(newState, addedTags, removedTags) => {
            // console.log(newState, addedTags, removedTags)
            window.dispatchEvent(new CustomEvent("update_to_share"));
            ReactGA.pageview(window.location.pathname + window.location.search);
          }}
        />
        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
