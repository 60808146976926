import React from "react";
import { NavLink } from "react-router-dom";

// * STYLES
import "./c-list-timelines.scss";

export default (props) => {
  const b = props.rp_layout;
  const rp_fields = props.rp_fields;
  const rp_extra_params = props.rp_extra_params;

  // console.log(rp_fields)

  const timeline_years = [
    1900, 1910, 1920, 1930, 1940, 1950, 1960, 1970, 1980, 1990, 2000, 2010,
    2020, 2030,
  ];

  const year_w = 100 / 14;

  const positions = [
    { top: 0, left: year_w * 1 - year_w / 2 - (year_w / 10) * 5 + "%" }, // 0 - 1895-1915
    { top: 1, left: year_w * 3 - year_w / 2 - (year_w / 10) * 1 + "%" }, // 1 - 1919-1925
    { top: 0, left: year_w * 3 - year_w / 2 + (year_w / 10) * 5 + "%" }, // 2 - 1925-1935
    { top: 1, left: year_w * 5 - year_w / 2 + (year_w / 10) * 5 + "%" }, // 3 - 1945-1956
    { top: 0, left: year_w * 6 - year_w / 2 + (year_w / 10) * 6 + "%" }, // 4 - 1956-1975
    { top: 2, left: year_w * 6 - year_w / 2 + (year_w / 10) * 6 + "%" }, // 5 - 1956-1970
    { bot: 2, left: year_w * 7 - year_w / 2 - (year_w / 10) * 1 + "%" }, // 6 FREE CINEMA - 1959-1970
    { bot: 1, left: year_w * 7 - year_w / 2 - (year_w / 10) * 0 + "%" }, // 7 ESCUELA BARCELONA - 1960-1975
    { bot: 0, left: year_w * 7 - year_w / 2 - (year_w / 10) * 0 + "%" }, // 8 NUEVOS CINES ESTE - 1960-1968
    { top: 1, left: year_w * 8 - year_w / 2 + (year_w / 10) * 4 + "%" }, // 9 - 1974-1990
    { top: 0, left: year_w * 11 - year_w / 2 + (year_w / 10) * 5 + "%" }, // 10 - 2005-2019
    { top: 1, left: year_w * 11 - year_w / 2 + (year_w / 10) * 5 + "%" }, // 11 - 2005-2009
    { bot: 0, left: year_w * 12 - year_w / 2 - (year_w / 10) * 0 + "%" }, // 12 REBELION DIGITAL - 2010-2020
  ];

  const items_to_top = [
    "baeb0114-c1da-4993-9377-b9c1ddbc676d",
    "aaa17a7f-9f2f-4def-a4c6-9955e3e38ccf",
    "ce915ad5-91de-4e88-978f-9bc28b357449",
    "cbd33eef-bf96-4f66-8a57-2d791ca1b9bf",
  ];

  const grid_items_resp = [];
  const grid_items_top = [];
  const grid_items_bot = [];

  rp_fields.forEach((item, index) => {
    let _the_slug = item.slug;

    if (items_to_top.includes(item["id"])) {
      let _html = (
        <NavLink
          key={index}
          to={`/${rp_extra_params["rp_base_link"]}/${_the_slug}`}
          className={`${b}__item ${b}__item--top`}
          style={{
            "--years": item[`end_year`] - item[`start_year`],
            "--bot": positions[index].bot,
            "--left": positions[index].left,
          }}
        >
          <div className={`${b}__wrapper-years`}>
            <div className={`${b}__years`}></div>
          </div>
          <div className={`${b}__wrapper-name`}>
            <div className={`${b}__name`}>
              <span className={`${b}__name-start`}>{item[`start_year`]}</span>
              <span className={`${b}__name-sep`}></span>
              <span className={`${b}__name-year`}>{item[`end_year`]}</span>
            </div>
          </div>
          <div className={`${b}__wrapper-title`}>
            <h3 className={`${b}__title`}>{item[`name`]}</h3>
          </div>
        </NavLink>
      );

      grid_items_top.push(_html);
    } else {
      let _html = (
        <NavLink
          key={index}
          to={`/${rp_extra_params["rp_base_link"]}/${_the_slug}`}
          className={`${b}__item ${b}__item--bot`}
          style={{
            "--years": item[`end_year`] - item[`start_year`],
            "--top": positions[index].top,
            "--left": positions[index].left,
          }}
        >
          <div className={`${b}__wrapper-years`}>
            <div className={`${b}__years`}></div>
          </div>
          <div className={`${b}__wrapper-name`}>
            <div className={`${b}__name`}>
              <span className={`${b}__name-start`}>{item[`start_year`]}</span>
              <span className={`${b}__name-sep`}></span>
              <span className={`${b}__name-year`}>{item[`end_year`]}</span>
            </div>
          </div>
          <div className={`${b}__wrapper-title`}>
            <h3 className={`${b}__title`}>{item[`name`]}</h3>
          </div>
        </NavLink>
      );

      grid_items_bot.push(_html);
    }

    let _resp_html = (
      <NavLink
        key={index}
        to={`/${rp_extra_params["rp_base_link"]}/${_the_slug}`}
        className={`${b}__r-item`}
      >
        <div className={`${b}__r-wrapper-name`}>
          <div className={`${b}__r-name`}>
            <span className={`${b}__r-name-start`}>{item[`start_year`]}</span>
            <span className={`${b}__r-name-sep`}></span>
            <span className={`${b}__r-name-year`}>{item[`end_year`]}</span>
          </div>
        </div>
        <div className={`${b}__r-wrapper-title`}>
          <h3 className={`${b}__r-title`}>{item[`name`]}</h3>
        </div>
      </NavLink>
    );

    grid_items_resp.push(_resp_html);
  });

  return (
    <div className={`${b} js-${b}`}>
      <div className={`${b}__wrapper-top`}>{grid_items_top}</div>
      <div className={`${b}__wrapper-timelines`}>
        <div className={`${b}__timelines`}>
          {timeline_years.map((item, index) => {
            return (
              <div key={index} className={`${b}__decade`}>
                <div className={`${b}__decade-line`}></div>
                <div className={`${b}__decade-text`}>{item}</div>
                <div className={`${b}__decade-line`}></div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={`${b}__wrapper-bot`}>{grid_items_bot}</div>
      <div className={`${b}__wrapper-resp`}>
        <div className={`${b}__r-line`}></div>
        {grid_items_resp}
      </div>
    </div>
  );
};
