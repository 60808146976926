import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";
import { DefaultTemplate } from "../templates/default-template";

import rp_fetch_data from "../data/fetch-data.json";
const { base_url, headers, localized_headers, url_get_event, url_get_media } =
  rp_fetch_data;

export class DetalleAcontecimientoPelicula extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      rp_movie: {},
      is_redirect: false,
    };
  }

  _setRedirect = () => {
    this.setState({
      is_redirect: true,
    });
  };

  _fetchData() {
    let _current_slug = this.props.match.params.slug;
    let _url = base_url + url_get_event.replace("__param__", _current_slug);

    let _current_slug_movie = this.props.match.params.slug_movie;
    let _url_media =
      base_url + url_get_media.replace("__param__", _current_slug_movie);

    Promise.all([
      fetch(_url, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
      fetch(_url_media, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
    ])
      .then(([res1, res2]) => Promise.all([res1.json(), res2.json()]))
      .then(([rp_data1, rp_data2]) => {
        if (rp_data1.errors) {
          this._setRedirect();
        }
        if (rp_data1.errors || rp_data2.errors) {
          console.log(rp_data1, rp_data2);
        } else {
          this._prepareComponents(rp_data1, rp_data2);
        }
      });
  }

  _prepareComponents = (rp_data1, rp_data2) => {
    let _components = [
      {
        layout: "c-movie",
        fields: rp_data2.data,
        extra_params: {
          rp_section: "event",
        },
      },
    ];

    let _link =
      this.context.lang === "en"
        ? `/en/event/${this.props.match.params.slug}`
        : `/es/acontecimiento/${this.props.match.params.slug}`;

    _components.unshift({
      layout: "c-label-page",
      fields: {
        label: rp_data1.data.name,
        link: _link,
        showIcon: true,
        highlight: true,
        showBorderBottom: true,
      },
    });
    // VARIABLE COLOR OR CREATE MORE PAGES
    let _rp_data = {
      title: rp_data1.data.name,
      slug: rp_data1.data.slug,
      primaryColor: "var(--color-b)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data, rp_movie: rp_data2.data });
  };

  _renderRedirect = () => {
    if (this.state.is_redirect) {
      return <Redirect to={`/${this.context.lang}/404`} />;
    }
  };

  componentDidMount() {
    this._fetchData();
  }

  render() {
    return (
      <>
        {this._renderRedirect()}
        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
