import React from "react";

import MCloseButton from "../_modules/m-close-button/m-close-button";

// * STYLES
import "./c-info.scss";

export default (props) => {
  const b = props.rp_layout;
  const rp_fields = props.rp_fields;
  const rp_extra_params = props.rp_extra_params;
  let _image_slug = false;
  let _title = rp_fields[`name`];
  let _content = rp_fields[`description`];
  let _start_date = null;
  let _end_date = null;
  let _print_date = null;
  let _url_image = "";

  if (
    ["personajes", "characters"].some((t) =>
      rp_extra_params["rp_base_link"].includes(t)
    )
  ) {
    _image_slug = "icon-character";
    _title = rp_fields[`name`] + " " + rp_fields[`last_name`];
    _content = rp_fields[`biography`];
    _start_date = rp_fields["birth_year"] ? rp_fields["birth_year"] : "????";
    _end_date = rp_fields["death_year"] ? rp_fields["death_year"] : "????";

    try {
      _url_image = require(`../../assets/all-icons/${_image_slug}__${rp_fields["slug"]}.svg`);
    } catch (ex) {
      // console.log(ex);
    }
  } else if (
    ["acontecimientos", "events"].some((t) =>
      rp_extra_params["rp_base_link"].includes(t)
    )
  ) {
    _image_slug = "icon-event";
    _start_date = rp_fields["start_year"] ? rp_fields["start_year"] : "????";
    _end_date = rp_fields["end_year"] ? rp_fields["end_year"] : "????";

    try {
      _url_image = require(`../../assets/all-icons/${_image_slug}__${rp_fields["slug"]}.svg`);
    } catch (ex) {
      // console.log(ex);
    }
  } else if (
    ["mapa", "map"].some((t) => rp_extra_params["rp_base_link"].includes(t))
  ) {
    _image_slug = "icon-map";
    _start_date = "";
    _end_date = "";

    try {
      _url_image = require(`../../assets/all-icons-flags/${rp_fields[
        "iso_code"
      ].toUpperCase()}.svg`);
    } catch (ex) {
      // console.log(ex);
    }
  }

  if (_end_date === "????") {
    _print_date = _start_date;
  } else {
    _print_date = _start_date + " - " + _end_date;
  }

  return (
    <div className={`${b} js-${b}`}>
      <div className={`${b}__top`}>
        <div className={`${b}__wrapper-title`}>
          <h1 className={`${b}__title`}>{_title}</h1>
          {_start_date && <div className={`${b}__date`}>({_print_date})</div>}
        </div>
        <div className={`${b}__wrapper-back`}>
          <MCloseButton
            link={`/${rp_extra_params["rp_base_link"]}/`}
            showIcon={true}
          />
        </div>
      </div>
      <div className={`${b}__body`}>
        {_url_image && (
          <div className={`${b}__wrapper-image`}>
            <img className={`${b}__image`} src={_url_image} alt="" />
          </div>
        )}
        <div className={`${b}__wrapper-content`}>
          <div
            className={`${b}__the-content`}
            dangerouslySetInnerHTML={{ __html: _content }}
          ></div>
        </div>
      </div>
    </div>
  );
};
