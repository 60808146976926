import React, { Component } from "react";
import { TimelineMax as Timeline, Power1 } from "gsap";
import AppContext from "../../context/AppContext";
import * as moment from "moment";
import "moment/locale/es";

import global_settings from "../../data/header-menu";

// * STYLES
import "./b-access.scss";

const b = "b-access";
// Retorna un entero aleatorio entre min (incluido) y max (excluido)
// ¡Usando Math.round() te dará una distribución no-uniforme!

const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min)) + min;
};

const random_num = getRandomInt(1, 16);

const B_access = class extends Component {
  static contextType = AppContext;

  componentDidMount() {}

  _access_out() {
    let _access = document.querySelector(".js-access");

    new Timeline()
      .to(_access, 0.2, {
        // NOTHING
      })
      .addLabel("start")
      .to(
        _access,
        0.3,
        {
          autoAlpha: 0,
          ease: Power1.easeOut,
        },
        "start"
      )
      .call(
        function () {
          let _body = document.querySelector("body");
          _body.classList.remove("o-overflow-hidden");
        },
        [],
        this
      );
  }

  render() {
    const header_menu = global_settings(this.context);
    let _date = moment().format("D MMMM YYYY");

    let _logo_the_img = "";
    let _logo_bg_img = "";

    try {
      _logo_the_img = require(`../../assets/images/MediaLogo.svg`);
      // do stuff
    } catch (ex) {
      console.log(ex);
    }

    try {
      _logo_bg_img = require(`../../assets/modal-access/TFT${random_num}.jpg`);
      // do stuff
    } catch (ex) {
      console.log(ex);
    }

    return (
      <div className={`${b} js-access`}>
        {_logo_bg_img && (
          <div className={`${b}__wrapper-bg`}>
            <img className={`${b}__bg-image`} src={_logo_bg_img} alt="" />
          </div>
        )}
        <div className={`${b}__wrapper`}>
          <div className={`${b}__wrapper-date`}>
            <span className={`${b}__date`}>{_date}</span>
          </div>
          <div className={`${b}__wrapper-title`}>
            <span className={`${b}__title`}>{header_menu[0].label}</span>
          </div>
          <div className={`${b}__wrapper-caption`}>
            <span className={`${b}__caption`}>{header_menu[0].extra_info}</span>
          </div>
          <div className={`${b}__wrapper-button`}>
            <span className={`${b}__button`} onClick={this._access_out}>
              {this.context.trans("general.access")}
            </span>
          </div>
        </div>
        {_logo_the_img && (
          <div className={`${b}__block-partner`}>
            <div className={`${b}__wrapper-logo-partner`}>
              <img
                className={`${b}__logo-partner`}
                src={_logo_the_img}
                alt=""
              />
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default B_access;
