import React, { Component } from "react";
import AllComponents from "../components/all-components";
import Footer from "../footer";

export class DefaultTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidUpdate() {
    const { rp_data } = this.props;
    const { primaryColor } = rp_data;
    let _primary_color = primaryColor ? primaryColor : false;
    let _is_detail = document.querySelectorAll(".js-c-movie");
    let _body = document.querySelector("body");

    if (_is_detail.length > 0) {
      _body.classList.add("is-detail");
    } else {
      _body.classList.remove("is-detail");
    }

    if (_primary_color) {
      document.documentElement.style.setProperty(
        "--current-primary-color",
        _primary_color
      );
      document.documentElement.style.setProperty(
        "--current-primary-color-string",
        '"' + _primary_color + '"'
      );
      let _primary_color_alt = _primary_color.replace(")", "-alt)");
      document.documentElement.style.setProperty(
        "--current-primary-color-alt",
        _primary_color_alt
      );
      document.documentElement.style.setProperty(
        "--current-primary-color-alt-string",
        '"' + _primary_color_alt + '"'
      );
    }
  }

  render() {
    const { rp_data } = this.props;

    return (
      <>
        <main
          className={`o-page-transition js-page-transition js-smooth-scrollbar is-${rp_data.slug}`}
        >
          <div className="o-page-transition__inner js-page-transition-inner">
            {rp_data.components ? (
              <AllComponents rp_components={rp_data.components} />
            ) : (
              "loading"
            )}
          </div>
        </main>
        <Footer />
      </>
    );
  }
}
