import React from "react";
import classNames from "classnames";

export default (props) => {
  let classes = classNames(
    { [`${props.b}__container`]: props.b },
    "o-container",
    { [`${props.extra_classes}`]: props.extra_classes }
  );

  return <div className={classes}>{props.children}</div>;
};
