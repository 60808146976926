import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../context/AppContext";
import { DefaultTemplate } from "../templates/default-template";
import Helmet from "react-helmet";
import ReactGA from "react-ga";

import rp_fetch_data from "../data/fetch-data.json";
const {
  base_url,
  headers,
  localized_headers,
  url_get_countries,
  url_get_country,
  url_get_medias,
} = rp_fetch_data;

export class DetalleMapa extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      is_redirect: false,
    };
  }

  _setRedirect = () => {
    this.setState({
      is_redirect: true,
    });
  };

  _renderRedirect = () => {
    if (this.state.is_redirect) {
      return <Redirect to={`/${this.context.lang}/404`} />;
    }
  };

  _fetchData(all_countries) {
    let _country = all_countries.filter(
      (item) => item.slug === this.props.match.params.slug
    );
    // console.log(all_countries)
    if (_country.length === 0) {
      this._setRedirect();
    } else {
      let _current_slug = _country[0].iso_code.toLowerCase();
      let _url = base_url + url_get_country.replace("__param__", _current_slug);
      let _url_medias = _url + url_get_medias;

      Promise.all([
        fetch(_url_medias, {
          method: "GET",
          headers: new Headers({
            ...headers,
            ...localized_headers[this.context.lang],
          }),
        }),
      ])
        .then(([res1]) => Promise.all([res1.json()]))
        .then(([rp_data]) => {
          if (rp_data.errors) {
            this._setRedirect();
          }
          if (rp_data.data.length === 0) {
            this._setRedirect();
          } else {
            this._prepareComponents(rp_data, all_countries);
          }
        });
    }
  }

  _prepareComponents = (rp_data, all_countries) => {
    let _country = all_countries.filter(
      (item) => item.slug === this.props.match.params.slug
    );

    const lang = this.context.lang || "es";

    let _components = [
      {
        layout: "c-info",
        fields: {
          name: _country[0].name,
          iso_code: _country[0].iso_code,
          description: "",
        },
        extra_params: {
          rp_base_link: lang === "en" ? "en/map" : "es/mapa",
        },
      },
      {
        layout: "c-medias",
        fields: rp_data.data,
        extra_params: {
          rp_base_link: lang === "en" ? "en/map" : "es/mapa",
          rp_extra_link: this.props.match.params.slug,
        },
      },
    ];
    let _rp_data = {
      title: _country[0].name,
      slug: _country[0].slug,
      primaryColor: "var(--color-e)",
      components: _components,
    };
    this.setState({ rp_data: _rp_data });
  };

  componentDidMount() {
    let _url = base_url + url_get_countries;

    Promise.all([
      fetch(_url, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
    ])
      .then(([res_countries]) => Promise.all([res_countries.json()]))
      .then(([rp_data_countries]) => {
        if (rp_data_countries.errors) {
          console.log(rp_data_countries);
        } else {
          this._fetchData(rp_data_countries.data);
        }
      });
  }

  render() {
    let _title =
      this.context.trans("general.the_filmin_times") +
      " - " +
      this.context.trans("general.map");
    let _metadesc = "";
    let _meta = [];
    let _url = window.location.href;

    if (this.state.rp_data) {
      _metadesc = this.state.rp_data.title;
    }

    return (
      <>
        {this._renderRedirect()}
        {this.state.rp_data && (
          <Helmet
            title={_title}
            titleTemplate={`%s | ${_metadesc}`}
            meta={[
              {
                property: `og:title`,
                content: _title,
              },
              {
                property: `og:type`,
                content: `website`,
              },
              {
                property: `og:url`,
                content: _url,
              },
              {
                name: `description`,
                content: _metadesc,
              },
              {
                property: `og:description`,
                content: _metadesc,
              },
            ].concat(_meta)}
            onChangeClientState={(newState, addedTags, removedTags) => {
              // console.log(newState, addedTags, removedTags)
              window.dispatchEvent(new CustomEvent("update_to_share"));
              ReactGA.pageview(
                window.location.pathname + window.location.search
              );
            }}
          />
        )}

        <DefaultTemplate rp_data={this.state.rp_data} />
      </>
    );
  }
}
