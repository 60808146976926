import React, { Component } from "react";
import AppContext from "../../context/AppContext";
import CSelect from "../c-select/c-select";
import CMapRender from "../c-map-render/c-map-render";

// * STYLES
import "./c-map.scss";

import rp_fetch_data from "../../data/fetch-data.json";
const { base_url, headers, localized_headers, url_get_countries } =
  rp_fetch_data;

export default class C_map extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      rp_data: {},
      country: false,
      countries: false,
    };
  }

  _fetchData() {
    let _url = base_url + url_get_countries;

    Promise.all([
      fetch(_url, {
        method: "GET",
        headers: new Headers({
          ...headers,
          ...localized_headers[this.context.lang],
        }),
      }),
    ])
      .then(([res1]) => Promise.all([res1.json()]))
      .then(([rp_data1]) => {
        if (rp_data1.errors) {
          console.log(rp_data1);
        } else {
          this._prepareComponents(rp_data1);
        }
      });
  }

  _prepareComponents(rp_data) {
    rp_data.data.sort((a, b) => {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    // console.log(rp_data)
    this.setState({ countries: rp_data });
  }

  componentDidMount() {
    this._fetchData();
  }

  componentDidUpdate() {}

  _callbackFunction = (childData) => {
    this.setState({ country: childData });
  };

  render() {
    const b = this.props.rp_layout;

    let _countries = this.state.countries;
    let _is_ok = false;

    if (_countries.data) {
      _is_ok = true;
    }

    return (
      <div className={`${b} js-${b}`}>
        {_is_ok && (
          <CSelect
            countries={this.state.countries.data}
            parentCallback={this._callbackFunction}
          ></CSelect>
        )}
        {_is_ok && (
          <CMapRender
            countries={this.state.countries.data}
            country={this.state.country}
          ></CMapRender>
        )}
        {/* {
                    this.state.country && 
                        <C_map_bottom country={this.state.country}></C_map_bottom>
                } */}
      </div>
    );
  }
}
