// ! DEPRECATED FILE

import React from "react";

// OBJECTS - HTML
import OContainer from "../../objects/o-container/o-container";

// * STYLES
import "./c-text.scss";

// ! DEPRECATED FILE

export default (props) => {
  const b = props.rp_layout;
  const rp_fields = props.rp_fields;

  return (
    <div className={`${b} js-text`}>
      <OContainer b={b} extra_classes={`${b}__container`}>
        <div className={`${b}__wrapper-image`}>
          <div className={`${b}__image`}>
            {/* <All_images nonstretched={ true } src={ rp_fields[`${b}__image`] } ></All_images> */}
            {rp_fields[`${b}__title`]}
          </div>
        </div>
      </OContainer>
    </div>
  );
};

// ! DEPRECATED FILE
